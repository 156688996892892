<div class="modal-grid">
    <div class="modal-grid-column-6 contact-page-header">
        <h1 class="type-36 m-type-30-light">
            <slot name="title" />
        </h1>
        <div class="spacer-24 m-spacer-16" />
        <p class="type-18 m-type-15 color-text-60">
            <slot name="subtitle" />
        </p>
    </div>
</div>
