export default {
	installationCost: {
		studio: 115_000,
		onebed: 120_000,
		twobed: 125_000,
		xl8: 155_000,
		xl10: 160_000
	},
	deliveryCost: {
		studio: 12_000,
		onebed: 14_000,
		twobed: 14_000,
		xl8: 26_000,
		xl10: 32_000
	},
	options: [
		{
			key: "region",
			values: [
				{
					key: "socal",
					display: {
						title: "Southern California",
						alternativeTitle: "Southern<br>California",
						sf_field: "GEO_c",
						sf_value: "Southern California"
					}
				},
				{
					key: "nocal",
					display: {
						title: "San&nbsp;Francisco Bay&nbsp;Area",
						alternativeTitle: "San&nbsp;Francisco<br>Bay&nbsp;Area",
						sf_field: "GEO_c",
						sf_value: "San Francisco Bay Area"
					}
				},
				{
					key: "rest",
					display: {
						title: "Somewhere else",
						subtitle: "Coming soon",
						sf_field: "GEO_c",
						sf_value: "Other"
					}
				}
			],
			display: {
				label: "Region",
				title: "Where is your Backyard going?"
			}
		},
		{
			key: "layout",
			sf_field: "Unit_Layout__c",
			values: [
				{
					key: "studio",
					sf_value: "Studio",
					cost: 147_000,
					display: {
						title: "Studio",
						subtitle: "420 gross sq. ft.",
						primarySubtitle: "1 bath"
					}
				},
				{
					key: "onebed",
					sf_value: "One Bedroom",
					cost: 165_000,
					display: {
						title: "One bedroom",
						subtitle: "540 gross sq. ft.",
						primarySubtitle: "1 bedroom, 1 bath"
					}
				},
				{
					key: "twobed",
					sf_value: "Two Bedroom",
					cost: 185_000,
					display: {
						title: "Two bedroom",
						subtitle: "690 gross sq. ft.",
						primarySubtitle: "2 bedrooms, 1 bath"
					}
				},
				{
					key: "xl8",
					sf_value: "XL 8",
					cost: 233_000,
					display: {
						title: "Backyard XL 8",
						subtitle: "800 gross sq. ft.",
						primarySubtitle: "2 bedrooms, 2 baths"
					}
				},
				{
					key: "xl10",
					sf_value: "XL 10",
					cost: 261_000,
					display: {
						title: "Backyard XL 10",
						subtitle: "950 gross sq. ft.",
						primarySubtitle: "2 bedrooms, 2 baths"
					}
				}
			],
			display: {
				label: "Layout",
				title: "Choose your layout"
			}
		},
		{
			key: "cladding",
			sf_field: "Base_Material__c",
			values: [
				{
					key: "cement",
					sf_value: "Fiber Cement - Vertical",
					display: {
						title: "Fiber cement",
						subtitle: "Durable and weather resistant",
						list: [
							'Vertical 7" panels with V-groove',
							"Five standard colors or custom color",
							"Fire-, moisture-, and rot-resistant"
						]
					}
				},
				{
					key: "metal",
					sf_value: "Insulated Metal Panel",
					cost: ({ layout }) =>
						({
							studio: 23500,
							onebed: 27500,
							twobed: 32000,
							xl8: 45000,
							xl10: 50000
						})[layout],
					display: {
						title: "Smooth-coated steel",
						subtitle: "Lustrous and maintenance-free",
						list: [
							'Horizontal 24" smooth-coated steel insulated panels',
							"Three standard colors plus two premium colors",
							"Easy to clean and never needs to be painted",
							"Fire-, moisture-, and rot-resistant"
						]
					}
				}
			],
			display: {
				label: "Exterior material",
				title: "Choose your cladding material"
			}
		},
		{
			key: "color",
			sf_field: "Base_Color_Selection__c",
			values: [
				{
					key: "base-bonewhite",
					sf_value: "Bone White",
					display: {
						title: "Bone white",
						description: "A warm and simple white. Classic."
					}
				},
				{
					key: "base-driftwood",
					sf_value: "Driftwood",
					display: {
						title: "Driftwood",
						description:
							"A flexible and serene gray with cool undertones."
					}
				},
				{
					key: "base-parchment",
					sf_value: "Parchment",
					display: {
						title: "Parchment",
						description: "An inviting, earthy beige. Like stucco."
					}
				},
				{
					key: "base-evergreen",
					sf_value: "Evergreen",
					cost: ({ cladding }) =>
						({ cement: 0, metal: 5000 })[cladding],
					display: {
						title: "Evergreen",
						description:
							"A deep and vibrant green with a hint of earthiness."
					}
				},
				{
					key: "base-dark-bronze",
					sf_value: "Dark Bronze",
					cost: ({ cladding }) =>
						({ cement: 0, metal: 5000 })[cladding],
					display: {
						title: "Dark bronze",
						description: "Rich and warm. Most definitely not black."
					}
				},
				{
					key: "custom",
					sf_value: "Custom (Fiber Cement Only)",
					cost: ({ layout }) =>
						layout?.startsWith("xl") ? 4000 : 3000,
					conditions: {
						cladding: (cladding) => cladding === "cement"
					},
					display: {
						title: "Custom color",
						subtitle: "Not shown and specified later"
					}
				}
			],
			display: {
				label: "Cladding color",
				title: "Choose your cladding color"
			}
		},
		{
			key: "roof",
			sf_field: "Roof_Color__c",
			values: [
				{
					key: "roof-dark-bronze",
					sf_value: "Dark Bronze",
					display: {
						title: "Dark bronze",
						description: "Extremely versatile, deep and muted."
					}
				},
				{
					key: "roof-metallic-silver",
					sf_value: "Silver",
					cost: 2500,
					display: {
						title: "Metallic silver",
						description: "Slightly warm with a subtle fleck."
					}
				}
			],
			display: {
				label: "Roof",
				title: "Choose your roof color"
			}
		},
		{
			key: "entry",
			sf_field: "Studio_Entry__c",
			conditions: {
				layout: (layout) => layout === "studio"
			},
			values: [
				{
					key: "entry-side",
					sf_value: "Side Entry",
					display: {
						title: "Side entry",
						summaryLabel: "Side entry",
						subtitle: "Enter through the hallway"
					}
				},
				{
					key: "entry-front",
					sf_value: "Front Entry",
					cost: 3000,
					display: {
						title: "Front entry",
						summaryLabel: "Front entry",
						subtitle: "Enter directly into the living area"
					}
				}
			],
			display: {
				label: "Entry",
				title: "Choose your entrance location",
				camera: "right-angle"
			}
		},
		{
			key: "front",
			sf_field: "Front_Option__c",
			conditions: {
				entry: (entry) => entry === undefined || entry === "entry-side"
			},
			values: [
				{
					key: "front-window",
					sf_value: "Casement Window",
					display: {
						title: "Casement window",
						summaryLabel: "Front casement window",
						subtitle: "3'&thinsp;×&thinsp;5'"
					}
				},
				{
					key: "front-doors",
					sf_value: "Double Doors",
					cost: 7000,
					display: {
						title: "Double doors",
						summaryLabel: "Front double doors",
						subtitle:
							"<nobr>3'&thinsp;×&thinsp;6'8\"</nobr> glass doors"
					}
				}
			],
			display: {
				label: "Front",
				title: "Choose your front window&nbsp;or&nbsp;doors",
				camera: "right-angle"
			}
		},
		{
			key: "left",
			sf_field: "Side_Option__c",
			conditions: {
				layout: (layout) => layout?.startsWith("xl") === false
			},
			values: [
				{
					key: "side-window",
					sf_value: "Casement Window",
					display: {
						title: "Casement Window",
						summaryLabel: "Left side casement window",
						subtitle: "3'&thinsp;×&thinsp;5'"
					}
				},
				{
					key: "side-doors",
					sf_value: "Double Doors",
					cost: 7000,
					display: {
						title: "Double doors",
						summaryLabel: "Left side double doors",
						subtitle:
							"<nobr>3'&thinsp;×&thinsp;6'8\"</nobr> glass doors"
					}
				}
			],
			display: {
				label: "Left side",
				title: ({ layout }) =>
					layout === "twobed"
						? "Choose your secondary bedroom window&nbsp;or&nbsp;doors"
						: "Choose your living room window&nbsp;or&nbsp;doors",
				camera: "left-angle"
			}
		},
		{
			key: "xl-living-room-side",
			sf_field: "Side_Option__c",
			conditions: {
				layout: (layout) => layout?.startsWith("xl") === true
			},
			values: [
				{
					key: "window",
					sf_value: "Casement Window",
					display: {
						title: "Casement window",
						summaryLabel: "Living room casement window",
						subtitle: "3'&thinsp;×&thinsp;5'"
					}
				},
				{
					key: "doors",
					sf_value: "Double Doors",
					cost: 7000,
					display: {
						title: "Double doors",
						summaryLabel: "Living room double doors",
						subtitle:
							"<nobr>3'&thinsp;×&thinsp;6'8\"</nobr> glass doors"
					}
				}
			],
			display: {
				label: "Living room side",
				title: "Choose your living room window&nbsp;or&nbsp;doors",
				camera: "right-angle"
			}
		},
		{
			key: "xl-primary-bedroom-side",
			sf_field: "Primary_Bedroom_Option__c",
			conditions: {
				layout: (layout) => layout?.startsWith("xl") === true
			},
			values: [
				{
					key: "window",
					sf_value: "Casement Window",
					display: {
						title: "Casement window",
						summaryLabel: "Primary bedroom casement window",
						subtitle: "3'&thinsp;×&thinsp;5'"
					}
				},
				{
					key: "doors",
					sf_value: "Double Doors",
					cost: 7000,
					display: {
						title: "Double doors",
						summaryLabel: "Primary bedroom double doors",
						subtitle:
							"<nobr>3'&thinsp;×&thinsp;6'8\"</nobr> glass doors"
					}
				}
			],
			display: {
				label: "Primary bedroom room side",
				title: "Choose your primary bedroom window&nbsp;or&nbsp;doors",
				camera: ({ layout }) =>
					layout === "xl8" ? "left-angle" : "right-angle-back"
			}
		},
		{
			key: "xl-secondary-bedroom-side",
			sf_field: "Secondary_Bedroom_Option__c",
			conditions: {
				layout: (layout) => layout?.startsWith("xl")
			},
			values: [
				{
					key: "window",
					sf_value: "Casement Window",
					display: {
						title: "Casement window",
						summaryLabel: "Secondary bedroom casement window",
						subtitle: "3'&thinsp;×&thinsp;5'"
					}
				},
				{
					key: "doors",
					sf_value: "Double Doors",
					cost: 7000,
					display: {
						title: "Double doors",
						summaryLabel: "Secondary bedroom double doors",
						subtitle:
							"<nobr>3'&thinsp;×&thinsp;6'8\"</nobr> glass doors"
					}
				}
			],
			display: {
				label: "Secondary bedroom room side",
				title: "Choose your secondary bedroom window&nbsp;or&nbsp;doors",
				camera: ({ layout }) =>
					layout === "xl8" ? "right-angle" : "right-angle-back"
			}
		},
		{
			key: "solar",
			sf_field: "Solar_Option__c",
			values: [
				{
					key: "solar-half",
					sf_value: "Half Solar",
					cost: ({ layout }) =>
						({
							studio: 8100,
							onebed: 10200,
							twobed: 13500,
							xl8: 8100,
							xl10: 10200
						})[layout],
					display: {
						title: ({ layout }) =>
							layout?.startsWith("xl")
								? "Half solar array"
								: "Solar array",
						subtitle: ({ layout }) =>
							layout?.startsWith("xl")
								? "Offset your energy usage"
								: "Go net zero&thinsp;¹",
						summaryLabel: "Half solar array",
						description: ({ layout }) =>
							layout === "studio"
								? "7-panel, 2.5 kW DC photovoltaic system"
								: layout === "onebed"
									? "9-panel, 3.4 kW DC photovoltaic system"
									: "12-panel, 4.5 kW DC photovoltaic system"
					}
				},
				{
					key: "solar-full",
					sf_value: "Full Solar",
					cost: ({ layout }) =>
						({
							studio: 16200,
							onebed: 20400,
							twobed: 27000,
							xl8: 16200,
							xl10: 20400
						})[layout],
					conditions: {
						layout: (layout) => layout?.startsWith("xl")
					},
					display: {
						title: "Full solar array",
						subtitle: ({ layout }) =>
							layout?.startsWith("xl")
								? "Go net zero&thinsp;¹"
								: "Offset even more energy usage",
						summaryLabel: "Full solar array",
						description: ({ layout }) =>
							layout === "studio"
								? "14-panel, 5.3 kW DC photovoltaic system"
								: layout === "onebed"
									? "18-panel, 6.8 kW DC photovoltaic system"
									: "24-panel, 9.1 kW DC photovoltaic system"
					}
				},
				{
					key: "solar-no",
					sf_value: "No Solar",
					display: {
						title: "No solar",
						summaryLabel: "None",
						subtitle: "Power with the grid",
						description: "No solar"
					}
				}
			],
			display: {
				label: "Solar",
				title: "Add solar panels",
				camera: "roof"
			}
		},
		{
			key: "powerwall",
			sf_field: "Powerwall__c",
			conditions: {
				solar: (value) => value !== "solar-no"
			},
			values: [
				{
					key: "standard",
					sf_value: "Standard"
				},
				{
					key: "powerwall",
					sf_value: "Tesla Powerwall 3",
					cost: 16500,
					display: {
						title: "Tesla Powerwall 3",
						subtitle: (c) =>
							c["solar"] === "solar-no"
								? "Available with solar array"
								: "Use your clean energy 24/7"
					}
				}
			],
			display: {
				label: "Tesla Powerwall 3",
				camera: ({ layout }) =>
					layout === "xl10" ? "left-back" : "left-angle-back"
			}
		},
		{
			key: "kitchen-style",
			conditions: {
				layout: (layout) => layout?.startsWith("xl") === false
			},
			values: [
				{
					key: "standard",
					sf_value: "Open Shelving",
					display: {
						title: "Open shelves",
						subtitle: "Light and airy"
					}
				},
				{
					key: "closed",
					sf_value: "Upper cabinets",
					cost: 2000,
					display: {
						title: "Closed cabinets",
						subtitle: '36" tall cabinets that hide clutter'
					}
				}
			],
			display: {
				label: "Kitchen style",
				title: "Choose your kitchen style"
			}
		},
		{
			key: "kitchen-pantry",
			sf_field: "Kitchen_Add_ons__c",
			conditions: {
				layout: (layout) => layout?.startsWith("xl") === false
			},
			values: [
				{
					key: "standard",
					sf_value: "Standard",
					display: {
						title: "Standard"
					}
				},
				{
					key: "pantry",
					sf_value: "Freestanding kitchen pantry",
					cost: 2000,
					display: {
						title: "Freestanding kitchen pantry",
						subtitle: "Extra space to stash your air&nbsp;fryer"
					}
				}
			],
			display: {
				title: "Optional kitchen upgrades"
			}
		},
		{
			key: "kitchen-layout",
			sf_field: "Kitchen_Layout__c",
			conditions: {
				layout: (layout) => layout === "xl10"
			},
			values: [
				{
					key: "standard",
					sf_value: "Standard",
					display: {
						title: "Standard kitchen layout",
						subtitle: "Bring your own dining table"
					}
				},
				{
					key: "island",
					sf_value: "Kitchen island",
					cost: 5000,
					display: {
						title: "Kitchen with island",
						subtitle: "Add counter and storage space"
					}
				}
			],
			display: {
				label: "Kitchen layout",
				title: "Choose your kitchen layout"
			}
		},
		{
			key: "kitchen-cooler",
			sf_field: "Kitchen_Cooler__c",
			conditions: {
				"layout": (value) => value === "xl10",
				"kitchen-layout": (value) => value === "island"
			},
			values: [
				{
					key: "standard",
					sf_value: "Standard"
				},
				{
					key: "island",
					sf_value: "Wine and beverage cooler",
					cost: 2500,
					display: {
						title: "Wine and beverage cooler",
						subtitle: (c) =>
							c["kitchen-layout"] === "island"
								? "Chill your Barolo perfectly"
								: "Available with kitchen island"
					}
				}
			],
			display: {
				label: "Wine and beverage cooler"
			}
		},
		{
			key: "primary-bedroom-wardrobe",
			sf_field: "Primary_Bedroom_Wardrobe__c",
			conditions: {
				layout: (layout) => layout === "onebed" || layout === "twobed"
			},
			values: [
				{
					key: "standard",
					sf_value: "Standard",
					display: {
						title: "Standard wardrobe"
					}
				},
				{
					key: "full",
					sf_value: "Full Width",
					cost: 1750,
					display: {
						title: "Full-width wardrobe"
					}
				}
			],
			display: {
				title: "Choose storage for this bedroom"
			}
		},
		{
			key: "secondary-bedroom-wardrobe",
			sf_field: "Secondary_Bedroom_Wardrobe__c",
			conditions: {
				layout: (layout) => layout === "twobed"
			},
			values: [
				{
					key: "standard",
					sf_value: "Standard",
					display: {
						title: "Standard wardrobe"
					}
				},
				{
					key: "full",
					sf_value: "Full Width",
					cost: 1750,
					display: {
						title: "Full-width wardrobe"
					}
				}
			],
			display: {
				title: "Choose storage for this bedroom"
			}
		},
		{
			key: "bathroom-accessibility-kit",
			sf_field: "Bathroom_Accessibility_Kit__c",
			values: [
				{
					key: "standard",
					sf_value: "Standard",
					display: {
						title: "Standard"
					}
				},
				{
					key: "bathroom-accessibility-kit",
					sf_value: "Bathroom accessibility kit",
					display: {
						title: "Bathroom accessibility kit",
						subtitle: "Enhance safety and ease of use",
						cost: "Free"
					}
				}
			],
			display: {}
		},
		{
			key: "interior-blinds",
			sf_field: "Blinds__c",
			values: [
				{
					key: "standard",
					sf_value: "No blinds",
					display: {
						title: "Standard"
					}
				},
				{
					key: "blinds-roller",
					sf_value: "Rolling blinds",
					cost: (c) =>
						({
							studio: 1500,
							onebed: 1750,
							twobed: 2000,
							xl8: 2250,
							xl10: 2500
						})[c.layout] +
						(c.left === "side-doors" ? 300 : 0) +
						(c.entry === "entry-front" || c.front === "front-doors"
							? 300
							: 0) +
						(c["xl-living-room-side"] === "doors" ? 300 : 0) +
						(c["xl-primary-bedroom-side"] === "doors" ? 300 : 0) +
						(c["xl-secondary-bedroom-side"] === "doors" ? 300 : 0),
					display: {
						title: "Roller blinds",
						subtitle: "More privacy without losing light"
					}
				}
			],
			display: {}
		}
	],
	zips: {
		network: [
			"90001",
			"90002",
			"90003",
			"90004",
			"90005",
			"90006",
			"90007",
			"90008",
			"90011",
			"90012",
			"90013",
			"90014",
			"90015",
			"90016",
			"90017",
			"90018",
			"90019",
			"90020",
			"90021",
			"90022",
			"90023",
			"90024",
			"90025",
			"90026",
			"90027",
			"90028",
			"90029",
			"90031",
			"90032",
			"90033",
			"90034",
			"90035",
			"90036",
			"90037",
			"90038",
			"90039",
			"90040",
			"90041",
			"90042",
			"90043",
			"90044",
			"90045",
			"90046",
			"90047",
			"90048",
			"90049",
			"90056",
			"90057",
			"90058",
			"90059",
			"90061",
			"90062",
			"90063",
			"90064",
			"90065",
			"90066",
			"90067",
			"90068",
			"90069",
			"90071",
			"90073",
			"90077",
			"90089",
			"90094",
			"90095",
			"90201",
			"90210",
			"90211",
			"90212",
			"90220",
			"90221",
			"90222",
			"90230",
			"90232",
			"90240",
			"90241",
			"90242",
			"90245",
			"90247",
			"90248",
			"90249",
			"90250",
			"90254",
			"90255",
			"90260",
			"90262",
			"90263",
			"90265",
			"90266",
			"90270",
			"90272",
			"90274",
			"90275",
			"90277",
			"90278",
			"90280",
			"90290",
			"90291",
			"90292",
			"90293",
			"90301",
			"90302",
			"90303",
			"90304",
			"90305",
			"90401",
			"90402",
			"90403",
			"90404",
			"90405",
			"90501",
			"90502",
			"90503",
			"90504",
			"90505",
			"90506",
			"90601",
			"90602",
			"90603",
			"90604",
			"90605",
			"90606",
			"90620",
			"90621",
			"90623",
			"90630",
			"90631",
			"90638",
			"90639",
			"90640",
			"90650",
			"90660",
			"90670",
			"90680",
			"90701",
			"90703",
			"90706",
			"90710",
			"90712",
			"90713",
			"90715",
			"90716",
			"90717",
			"90720",
			"90723",
			"90731",
			"90732",
			"90740",
			"90742",
			"90743",
			"90744",
			"90745",
			"90746",
			"90747",
			"90755",
			"90802",
			"90803",
			"90804",
			"90805",
			"90806",
			"90807",
			"90808",
			"90810",
			"90813",
			"90814",
			"90815",
			"90822",
			"90840",
			"91001",
			"91006",
			"91007",
			"91008",
			"91010",
			"91011",
			"91016",
			"91020",
			"91024",
			"91030",
			"91040",
			"91042",
			"91101",
			"91103",
			"91104",
			"91105",
			"91106",
			"91107",
			"91108",
			"91123",
			"91201",
			"91202",
			"91203",
			"91204",
			"91205",
			"91206",
			"91207",
			"91208",
			"91210",
			"91214",
			"91301",
			"91302",
			"91303",
			"91304",
			"91306",
			"91307",
			"91311",
			"91316",
			"91320",
			"91321",
			"91324",
			"91325",
			"91326",
			"91330",
			"91331",
			"91335",
			"91340",
			"91342",
			"91343",
			"91344",
			"91345",
			"91350",
			"91351",
			"91352",
			"91354",
			"91355",
			"91356",
			"91360",
			"91361",
			"91362",
			"91364",
			"91367",
			"91371",
			"91377",
			"91381",
			"91384",
			"91387",
			"91390",
			"91401",
			"91402",
			"91403",
			"91405",
			"91406",
			"91411",
			"91423",
			"91436",
			"91501",
			"91502",
			"91504",
			"91505",
			"91506",
			"91521",
			"91522",
			"91523",
			"91601",
			"91602",
			"91604",
			"91605",
			"91606",
			"91607",
			"91608",
			"91701",
			"91702",
			"91706",
			"91708",
			"91709",
			"91710",
			"91711",
			"91722",
			"91723",
			"91724",
			"91730",
			"91731",
			"91732",
			"91733",
			"91737",
			"91739",
			"91740",
			"91741",
			"91744",
			"91745",
			"91746",
			"91748",
			"91750",
			"91752",
			"91754",
			"91755",
			"91759",
			"91761",
			"91762",
			"91763",
			"91764",
			"91765",
			"91766",
			"91767",
			"91768",
			"91770",
			"91773",
			"91775",
			"91776",
			"91780",
			"91784",
			"91786",
			"91789",
			"91790",
			"91791",
			"91792",
			"91801",
			"91803",
			"91902",
			"91910",
			"91911",
			"91913",
			"91914",
			"91915",
			"91917",
			"91932",
			"91935",
			"91941",
			"91942",
			"91945",
			"91950",
			"91977",
			"91978",
			"91980",
			"92003",
			"92007",
			"92008",
			"92009",
			"92010",
			"92011",
			"92014",
			"92019",
			"92020",
			"92021",
			"92024",
			"92025",
			"92026",
			"92027",
			"92028",
			"92029",
			"92037",
			"92040",
			"92054",
			"92055",
			"92056",
			"92057",
			"92058",
			"92059",
			"92061",
			"92064",
			"92065",
			"92067",
			"92069",
			"92071",
			"92075",
			"92078",
			"92081",
			"92082",
			"92083",
			"92084",
			"92091",
			"92093",
			"92096",
			"92101",
			"92102",
			"92103",
			"92104",
			"92105",
			"92106",
			"92107",
			"92108",
			"92109",
			"92110",
			"92111",
			"92113",
			"92114",
			"92115",
			"92116",
			"92117",
			"92118",
			"92119",
			"92120",
			"92121",
			"92122",
			"92123",
			"92124",
			"92126",
			"92127",
			"92128",
			"92129",
			"92130",
			"92131",
			"92134",
			"92135",
			"92136",
			"92139",
			"92140",
			"92145",
			"92152",
			"92154",
			"92155",
			"92173",
			"92182",
			"92201",
			"92203",
			"92210",
			"92211",
			"92220",
			"92223",
			"92230",
			"92234",
			"92236",
			"92240",
			"92241",
			"92253",
			"92254",
			"92256",
			"92258",
			"92260",
			"92262",
			"92264",
			"92270",
			"92276",
			"92282",
			"92305",
			"92313",
			"92314",
			"92316",
			"92317",
			"92318",
			"92320",
			"92321",
			"92322",
			"92324",
			"92325",
			"92335",
			"92336",
			"92337",
			"92339",
			"92341",
			"92346",
			"92350",
			"92352",
			"92354",
			"92358",
			"92359",
			"92373",
			"92374",
			"92376",
			"92377",
			"92378",
			"92382",
			"92385",
			"92391",
			"92397",
			"92399",
			"92401",
			"92404",
			"92405",
			"92407",
			"92408",
			"92410",
			"92411",
			"92415",
			"92501",
			"92503",
			"92504",
			"92505",
			"92506",
			"92507",
			"92508",
			"92509",
			"92518",
			"92521",
			"92530",
			"92532",
			"92539",
			"92543",
			"92544",
			"92545",
			"92548",
			"92549",
			"92551",
			"92553",
			"92555",
			"92557",
			"92561",
			"92562",
			"92563",
			"92567",
			"92570",
			"92571",
			"92582",
			"92583",
			"92584",
			"92585",
			"92586",
			"92587",
			"92590",
			"92591",
			"92592",
			"92595",
			"92596",
			"92602",
			"92603",
			"92604",
			"92606",
			"92610",
			"92612",
			"92614",
			"92617",
			"92618",
			"92620",
			"92624",
			"92625",
			"92626",
			"92627",
			"92629",
			"92630",
			"92637",
			"92646",
			"92647",
			"92648",
			"92649",
			"92651",
			"92653",
			"92655",
			"92656",
			"92657",
			"92660",
			"92661",
			"92662",
			"92663",
			"92672",
			"92673",
			"92675",
			"92676",
			"92677",
			"92679",
			"92683",
			"92688",
			"92691",
			"92692",
			"92694",
			"92697",
			"92701",
			"92703",
			"92704",
			"92705",
			"92706",
			"92707",
			"92708",
			"92780",
			"92782",
			"92801",
			"92802",
			"92804",
			"92805",
			"92806",
			"92807",
			"92808",
			"92821",
			"92823",
			"92831",
			"92832",
			"92833",
			"92835",
			"92840",
			"92841",
			"92843",
			"92844",
			"92845",
			"92860",
			"92861",
			"92865",
			"92866",
			"92867",
			"92868",
			"92869",
			"92870",
			"92879",
			"92880",
			"92881",
			"92882",
			"92883",
			"92886",
			"92887",
			"93001",
			"93003",
			"93004",
			"93010",
			"93012",
			"93013",
			"93015",
			"93021",
			"93022",
			"93023",
			"93030",
			"93033",
			"93035",
			"93036",
			"93040",
			"93041",
			"93042",
			"93043",
			"93060",
			"93063",
			"93065",
			"93066",
			"93067",
			"93101",
			"93103",
			"93105",
			"93106",
			"93108",
			"93109",
			"93110",
			"93111",
			"93117",
			"93204",
			"93210",
			"93224",
			"93225",
			"93249",
			"93251",
			"93252",
			"93254",
			"93268",
			"93401",
			"93402",
			"93405",
			"93407",
			"93420",
			"93422",
			"93426",
			"93427",
			"93428",
			"93429",
			"93430",
			"93432",
			"93433",
			"93434",
			"93435",
			"93436",
			"93437",
			"93440",
			"93441",
			"93442",
			"93444",
			"93445",
			"93446",
			"93449",
			"93450",
			"93451",
			"93452",
			"93453",
			"93454",
			"93455",
			"93458",
			"93460",
			"93461",
			"93463",
			"93465",
			"93510",
			"93550",
			"93563",
			"93635",
			"93901",
			"93905",
			"93906",
			"93907",
			"93908",
			"93920",
			"93923",
			"93924",
			"93925",
			"93926",
			"93927",
			"93930",
			"93932",
			"93933",
			"93940",
			"93943",
			"93944",
			"93950",
			"93953",
			"93955",
			"93960",
			"93962",
			"94002",
			"94005",
			"94010",
			"94014",
			"94015",
			"94019",
			"94020",
			"94021",
			"94022",
			"94024",
			"94025",
			"94027",
			"94028",
			"94029",
			"94030",
			"94035",
			"94037",
			"94038",
			"94040",
			"94041",
			"94043",
			"94044",
			"94060",
			"94061",
			"94062",
			"94063",
			"94065",
			"94066",
			"94070",
			"94074",
			"94080",
			"94085",
			"94086",
			"94087",
			"94089",
			"94102",
			"94103",
			"94104",
			"94105",
			"94107",
			"94108",
			"94109",
			"94110",
			"94111",
			"94112",
			"94114",
			"94115",
			"94116",
			"94117",
			"94118",
			"94121",
			"94122",
			"94123",
			"94124",
			"94127",
			"94128",
			"94129",
			"94130",
			"94131",
			"94132",
			"94133",
			"94134",
			"94158",
			"94301",
			"94303",
			"94304",
			"94305",
			"94306",
			"94401",
			"94402",
			"94403",
			"94404",
			"94501",
			"94502",
			"94503",
			"94505",
			"94506",
			"94507",
			"94508",
			"94509",
			"94510",
			"94511",
			"94512",
			"94513",
			"94514",
			"94515",
			"94517",
			"94518",
			"94519",
			"94520",
			"94521",
			"94523",
			"94525",
			"94526",
			"94528",
			"94530",
			"94531",
			"94533",
			"94534",
			"94535",
			"94536",
			"94538",
			"94539",
			"94541",
			"94542",
			"94544",
			"94545",
			"94546",
			"94547",
			"94548",
			"94549",
			"94550",
			"94551",
			"94552",
			"94553",
			"94555",
			"94556",
			"94558",
			"94559",
			"94560",
			"94561",
			"94563",
			"94564",
			"94565",
			"94566",
			"94567",
			"94568",
			"94569",
			"94571",
			"94572",
			"94574",
			"94576",
			"94577",
			"94578",
			"94579",
			"94580",
			"94582",
			"94583",
			"94585",
			"94586",
			"94587",
			"94588",
			"94589",
			"94590",
			"94591",
			"94592",
			"94595",
			"94596",
			"94597",
			"94598",
			"94599",
			"94601",
			"94602",
			"94603",
			"94605",
			"94606",
			"94607",
			"94608",
			"94609",
			"94610",
			"94611",
			"94612",
			"94613",
			"94618",
			"94619",
			"94621",
			"94702",
			"94703",
			"94704",
			"94705",
			"94706",
			"94707",
			"94708",
			"94709",
			"94710",
			"94720",
			"94801",
			"94803",
			"94804",
			"94805",
			"94806",
			"94901",
			"94903",
			"94904",
			"94920",
			"94922",
			"94923",
			"94924",
			"94925",
			"94928",
			"94929",
			"94930",
			"94931",
			"94933",
			"94937",
			"94938",
			"94939",
			"94940",
			"94941",
			"94945",
			"94946",
			"94947",
			"94949",
			"94951",
			"94952",
			"94954",
			"94956",
			"94957",
			"94960",
			"94963",
			"94964",
			"94965",
			"94970",
			"94971",
			"94972",
			"94973",
			"95002",
			"95003",
			"95004",
			"95005",
			"95006",
			"95008",
			"95010",
			"95012",
			"95013",
			"95014",
			"95017",
			"95018",
			"95019",
			"95020",
			"95023",
			"95030",
			"95032",
			"95033",
			"95035",
			"95037",
			"95039",
			"95043",
			"95045",
			"95046",
			"95050",
			"95051",
			"95053",
			"95054",
			"95060",
			"95062",
			"95064",
			"95065",
			"95066",
			"95070",
			"95073",
			"95075",
			"95076",
			"95110",
			"95111",
			"95112",
			"95113",
			"95116",
			"95117",
			"95118",
			"95119",
			"95120",
			"95121",
			"95122",
			"95123",
			"95124",
			"95125",
			"95126",
			"95127",
			"95128",
			"95129",
			"95130",
			"95131",
			"95132",
			"95133",
			"95134",
			"95135",
			"95136",
			"95138",
			"95139",
			"95140",
			"95141",
			"95148",
			"95192",
			"95206",
			"95219",
			"95220",
			"95242",
			"95258",
			"95360",
			"95363",
			"95377",
			"95391",
			"95401",
			"95403",
			"95404",
			"95405",
			"95407",
			"95409",
			"95412",
			"95421",
			"95425",
			"95436",
			"95439",
			"95441",
			"95442",
			"95444",
			"95446",
			"95448",
			"95450",
			"95452",
			"95457",
			"95461",
			"95462",
			"95465",
			"95467",
			"95472",
			"95476",
			"95492",
			"95497",
			"95603",
			"95605",
			"95606",
			"95607",
			"95608",
			"95610",
			"95612",
			"95615",
			"95616",
			"95618",
			"95620",
			"95621",
			"95624",
			"95626",
			"95627",
			"95628",
			"95630",
			"95632",
			"95637",
			"95638",
			"95640",
			"95641",
			"95645",
			"95648",
			"95650",
			"95652",
			"95653",
			"95655",
			"95658",
			"95659",
			"95660",
			"95661",
			"95662",
			"95663",
			"95664",
			"95668",
			"95669",
			"95670",
			"95672",
			"95673",
			"95677",
			"95678",
			"95682",
			"95683",
			"95687",
			"95688",
			"95690",
			"95691",
			"95693",
			"95694",
			"95695",
			"95698",
			"95742",
			"95746",
			"95747",
			"95757",
			"95758",
			"95762",
			"95765",
			"95776",
			"95811",
			"95814",
			"95815",
			"95816",
			"95817",
			"95818",
			"95819",
			"95820",
			"95821",
			"95822",
			"95823",
			"95824",
			"95825",
			"95826",
			"95827",
			"95828",
			"95829",
			"95830",
			"95831",
			"95832",
			"95833",
			"95834",
			"95835",
			"95836",
			"95837",
			"95838",
			"95841",
			"95842",
			"95843",
			"95864",
			"95937"
		],
		nocal: [
			"94501",
			"94502",
			"94536",
			"94537",
			"94538",
			"94539",
			"94540",
			"94541",
			"94542",
			"94543",
			"94544",
			"94545",
			"94546",
			"94550",
			"94551",
			"94552",
			"94555",
			"94557",
			"94560",
			"94566",
			"94568",
			"94577",
			"94578",
			"94579",
			"94580",
			"94586",
			"94587",
			"94588",
			"94601",
			"94602",
			"94603",
			"94604",
			"94605",
			"94606",
			"94607",
			"94608",
			"94609",
			"94610",
			"94611",
			"94612",
			"94613",
			"94614",
			"94615",
			"94617",
			"94618",
			"94619",
			"94620",
			"94621",
			"94622",
			"94623",
			"94624",
			"94625",
			"94649",
			"94659",
			"94660",
			"94661",
			"94662",
			"94666",
			"94701",
			"94702",
			"94703",
			"94704",
			"94705",
			"94706",
			"94707",
			"94708",
			"94709",
			"94710",
			"94712",
			"94720",
			"95646",
			"96120",
			"96156",
			"95601",
			"95629",
			"95640",
			"95642",
			"95654",
			"95665",
			"95666",
			"95669",
			"95675",
			"95685",
			"95689",
			"95699",
			"95914",
			"95916",
			"95917",
			"95926",
			"95927",
			"95928",
			"95929",
			"95930",
			"95938",
			"95940",
			"95941",
			"95942",
			"95948",
			"95954",
			"95958",
			"95965",
			"95966",
			"95967",
			"95968",
			"95969",
			"95973",
			"95974",
			"95976",
			"95978",
			"95221",
			"95222",
			"95223",
			"95224",
			"95225",
			"95226",
			"95228",
			"95229",
			"95232",
			"95233",
			"95245",
			"95246",
			"95247",
			"95248",
			"95249",
			"95250",
			"95251",
			"95252",
			"95254",
			"95255",
			"95257",
			"95912",
			"95932",
			"95950",
			"95955",
			"95970",
			"95979",
			"95987",
			"94505",
			"94506",
			"94507",
			"94509",
			"94511",
			"94513",
			"94514",
			"94516",
			"94517",
			"94518",
			"94519",
			"94520",
			"94521",
			"94522",
			"94523",
			"94524",
			"94525",
			"94526",
			"94527",
			"94528",
			"94529",
			"94530",
			"94531",
			"94547",
			"94548",
			"94549",
			"94553",
			"94556",
			"94561",
			"94563",
			"94564",
			"94565",
			"94569",
			"94570",
			"94572",
			"94575",
			"94582",
			"94583",
			"94595",
			"94596",
			"94597",
			"94598",
			"94801",
			"94802",
			"94803",
			"94804",
			"94805",
			"94806",
			"94807",
			"94808",
			"94820",
			"94850",
			"95531",
			"95532",
			"95538",
			"95543",
			"95548",
			"95567",
			"95613",
			"95614",
			"95619",
			"95623",
			"95633",
			"95634",
			"95635",
			"95636",
			"95644",
			"95651",
			"95656",
			"95664",
			"95667",
			"95672",
			"95682",
			"95684",
			"95709",
			"95720",
			"95721",
			"95726",
			"95735",
			"95762",
			"96142",
			"96150",
			"96151",
			"96152",
			"96154",
			"96155",
			"96157",
			"96158",
			"93210",
			"93234",
			"93242",
			"93602",
			"93605",
			"93606",
			"93607",
			"93608",
			"93609",
			"93611",
			"93612",
			"93613",
			"93616",
			"93619",
			"93621",
			"93622",
			"93624",
			"93625",
			"93626",
			"93627",
			"93628",
			"93630",
			"93631",
			"93634",
			"93640",
			"93641",
			"93642",
			"93646",
			"93648",
			"93649",
			"93650",
			"93651",
			"93652",
			"93654",
			"93656",
			"93657",
			"93660",
			"93662",
			"93664",
			"93667",
			"93668",
			"93675",
			"93701",
			"93702",
			"93703",
			"93704",
			"93705",
			"93706",
			"93707",
			"93708",
			"93709",
			"93710",
			"93711",
			"93712",
			"93714",
			"93715",
			"93716",
			"93717",
			"93718",
			"93720",
			"93721",
			"93722",
			"93723",
			"93724",
			"93725",
			"93726",
			"93727",
			"93728",
			"93729",
			"93730",
			"93737",
			"93740",
			"93741",
			"93744",
			"93745",
			"93747",
			"93750",
			"93755",
			"93760",
			"93761",
			"93764",
			"93765",
			"93771",
			"93772",
			"93773",
			"93774",
			"93775",
			"93776",
			"93777",
			"93778",
			"93779",
			"93780",
			"93784",
			"93786",
			"93790",
			"93791",
			"93792",
			"93793",
			"93794",
			"93844",
			"93888",
			"95913",
			"95920",
			"95939",
			"95943",
			"95951",
			"95963",
			"95988",
			"95501",
			"95502",
			"95503",
			"95511",
			"95514",
			"95518",
			"95519",
			"95521",
			"95524",
			"95525",
			"95526",
			"95528",
			"95534",
			"95536",
			"95537",
			"95540",
			"95542",
			"95545",
			"95546",
			"95547",
			"95549",
			"95550",
			"95551",
			"95553",
			"95554",
			"95555",
			"95556",
			"95558",
			"95559",
			"95560",
			"95562",
			"95564",
			"95565",
			"95569",
			"95570",
			"95571",
			"95573",
			"95589",
			"92328",
			"92384",
			"92389",
			"93513",
			"93514",
			"93515",
			"93522",
			"93526",
			"93530",
			"93542",
			"93545",
			"93549",
			"93203",
			"93205",
			"93206",
			"93215",
			"93216",
			"93220",
			"93224",
			"93226",
			"93238",
			"93240",
			"93241",
			"93249",
			"93250",
			"93251",
			"93255",
			"93263",
			"93268",
			"93276",
			"93280",
			"93283",
			"93285",
			"93287",
			"93301",
			"93302",
			"93303",
			"93304",
			"93305",
			"93306",
			"93307",
			"93308",
			"93309",
			"93311",
			"93312",
			"93313",
			"93314",
			"93380",
			"93381",
			"93382",
			"93383",
			"93384",
			"93385",
			"93386",
			"93387",
			"93388",
			"93389",
			"93390",
			"93501",
			"93502",
			"93504",
			"93505",
			"93516",
			"93518",
			"93519",
			"93523",
			"93527",
			"93528",
			"93531",
			"93554",
			"93555",
			"93556",
			"93561",
			"93581",
			"93202",
			"93204",
			"93212",
			"93230",
			"93232",
			"93239",
			"93245",
			"93246",
			"93266",
			"95422",
			"95423",
			"95424",
			"95426",
			"95435",
			"95443",
			"95451",
			"95453",
			"95457",
			"95458",
			"95461",
			"95464",
			"95467",
			"95485",
			"95493",
			"96009",
			"96068",
			"96109",
			"96113",
			"96114",
			"96117",
			"96119",
			"96121",
			"96123",
			"96127",
			"96128",
			"96130",
			"96132",
			"96136",
			"96137",
			"93584",
			"93586",
			"93590",
			"93601",
			"93604",
			"93610",
			"93614",
			"93636",
			"93637",
			"93638",
			"93639",
			"93643",
			"93644",
			"93645",
			"93653",
			"93669",
			"94901",
			"94903",
			"94904",
			"94912",
			"94913",
			"94914",
			"94915",
			"94920",
			"94924",
			"94925",
			"94929",
			"94930",
			"94933",
			"94937",
			"94938",
			"94939",
			"94940",
			"94941",
			"94942",
			"94945",
			"94946",
			"94947",
			"94948",
			"94949",
			"94950",
			"94956",
			"94957",
			"94960",
			"94963",
			"94964",
			"94965",
			"94966",
			"94970",
			"94971",
			"94972",
			"94973",
			"94974",
			"94976",
			"94977",
			"94978",
			"94979",
			"94998",
			"93623",
			"95306",
			"95311",
			"95318",
			"95325",
			"95338",
			"95345",
			"95389",
			"95410",
			"95415",
			"95417",
			"95418",
			"95420",
			"95427",
			"95428",
			"95429",
			"95432",
			"95437",
			"95445",
			"95449",
			"95454",
			"95456",
			"95459",
			"95460",
			"95463",
			"95466",
			"95468",
			"95469",
			"95470",
			"95481",
			"95482",
			"95488",
			"95490",
			"95494",
			"95585",
			"95587",
			"93620",
			"93635",
			"93661",
			"93665",
			"95301",
			"95303",
			"95312",
			"95315",
			"95317",
			"95322",
			"95324",
			"95333",
			"95334",
			"95340",
			"95341",
			"95343",
			"95344",
			"95348",
			"95365",
			"95369",
			"95374",
			"95388",
			"96006",
			"96015",
			"96054",
			"96101",
			"96104",
			"96108",
			"96110",
			"96112",
			"96115",
			"96116",
			"93512",
			"93517",
			"93529",
			"93541",
			"93546",
			"96107",
			"96133",
			"93426",
			"93450",
			"93901",
			"93902",
			"93905",
			"93906",
			"93907",
			"93908",
			"93912",
			"93915",
			"93920",
			"93921",
			"93922",
			"93923",
			"93924",
			"93925",
			"93926",
			"93927",
			"93928",
			"93930",
			"93932",
			"93933",
			"93940",
			"93942",
			"93943",
			"93944",
			"93950",
			"93953",
			"93954",
			"93955",
			"93960",
			"93962",
			"95004",
			"95012",
			"95039",
			"94503",
			"94508",
			"94515",
			"94558",
			"94559",
			"94562",
			"94567",
			"94573",
			"94574",
			"94576",
			"94581",
			"94599",
			"95712",
			"95924",
			"95945",
			"95946",
			"95949",
			"95959",
			"95960",
			"95975",
			"95977",
			"95986",
			"96160",
			"96161",
			"95602",
			"95603",
			"95604",
			"95631",
			"95648",
			"95650",
			"95658",
			"95661",
			"95663",
			"95677",
			"95678",
			"95681",
			"95701",
			"95703",
			"95713",
			"95714",
			"95715",
			"95717",
			"95722",
			"95724",
			"95728",
			"95736",
			"95746",
			"95747",
			"95765",
			"96140",
			"96141",
			"96143",
			"96145",
			"96146",
			"96148",
			"96162",
			"95915",
			"95923",
			"95934",
			"95947",
			"95956",
			"95971",
			"95980",
			"95983",
			"95984",
			"96020",
			"96103",
			"96105",
			"96106",
			"96122",
			"96129",
			"96135",
			"94203",
			"94204",
			"94205",
			"94206",
			"94207",
			"94208",
			"94209",
			"94211",
			"94229",
			"94230",
			"94232",
			"94234",
			"94235",
			"94236",
			"94237",
			"94239",
			"94240",
			"94244",
			"94245",
			"94246",
			"94247",
			"94248",
			"94249",
			"94250",
			"94252",
			"94254",
			"94256",
			"94257",
			"94258",
			"94259",
			"94261",
			"94262",
			"94263",
			"94267",
			"94268",
			"94269",
			"94271",
			"94273",
			"94274",
			"94277",
			"94278",
			"94279",
			"94280",
			"94282",
			"94283",
			"94284",
			"94285",
			"94286",
			"94287",
			"94288",
			"94289",
			"94290",
			"94291",
			"94293",
			"94294",
			"94295",
			"94296",
			"94297",
			"94298",
			"94299",
			"95608",
			"95609",
			"95610",
			"95611",
			"95615",
			"95621",
			"95624",
			"95626",
			"95628",
			"95630",
			"95632",
			"95638",
			"95639",
			"95641",
			"95652",
			"95655",
			"95660",
			"95662",
			"95670",
			"95671",
			"95673",
			"95680",
			"95683",
			"95690",
			"95693",
			"95741",
			"95742",
			"95757",
			"95758",
			"95759",
			"95763",
			"95811",
			"95812",
			"95813",
			"95814",
			"95815",
			"95816",
			"95817",
			"95818",
			"95819",
			"95820",
			"95821",
			"95822",
			"95823",
			"95824",
			"95825",
			"95826",
			"95827",
			"95828",
			"95829",
			"95830",
			"95831",
			"95832",
			"95833",
			"95834",
			"95835",
			"95836",
			"95837",
			"95838",
			"95840",
			"95841",
			"95842",
			"95843",
			"95851",
			"95852",
			"95853",
			"95860",
			"95864",
			"95865",
			"95866",
			"95867",
			"95887",
			"95894",
			"95899",
			"95023",
			"95024",
			"95043",
			"95045",
			"95075",
			"92309",
			"92310",
			"92311",
			"92312",
			"92329",
			"92331",
			"92364",
			"92398",
			"93558",
			"93562",
			"94101",
			"94102",
			"94103",
			"94104",
			"94105",
			"94106",
			"94107",
			"94108",
			"94109",
			"94110",
			"94111",
			"94112",
			"94114",
			"94115",
			"94116",
			"94117",
			"94118",
			"94119",
			"94120",
			"94121",
			"94122",
			"94123",
			"94124",
			"94125",
			"94126",
			"94127",
			"94129",
			"94130",
			"94131",
			"94132",
			"94133",
			"94134",
			"94135",
			"94136",
			"94137",
			"94138",
			"94139",
			"94140",
			"94141",
			"94142",
			"94143",
			"94144",
			"94145",
			"94146",
			"94147",
			"94150",
			"94151",
			"94152",
			"94153",
			"94154",
			"94155",
			"94156",
			"94158",
			"94159",
			"94160",
			"94161",
			"94162",
			"94163",
			"94164",
			"94171",
			"94172",
			"94175",
			"94177",
			"94188",
			"94199",
			"95201",
			"95202",
			"95203",
			"95204",
			"95205",
			"95206",
			"95207",
			"95208",
			"95209",
			"95210",
			"95211",
			"95212",
			"95213",
			"95215",
			"95219",
			"95220",
			"95227",
			"95230",
			"95231",
			"95234",
			"95236",
			"95237",
			"95240",
			"95241",
			"95242",
			"95253",
			"95258",
			"95267",
			"95269",
			"95296",
			"95297",
			"95304",
			"95320",
			"95330",
			"95336",
			"95337",
			"95366",
			"95376",
			"95377",
			"95378",
			"95391",
			"95686",
			"93401",
			"93402",
			"93403",
			"93405",
			"93406",
			"93407",
			"93408",
			"93409",
			"93410",
			"93412",
			"93420",
			"93421",
			"93422",
			"93423",
			"93424",
			"93428",
			"93430",
			"93432",
			"93433",
			"93442",
			"93443",
			"93444",
			"93445",
			"93446",
			"93447",
			"93448",
			"93449",
			"93451",
			"93452",
			"93453",
			"93461",
			"93465",
			"93475",
			"93483",
			"94002",
			"94005",
			"94010",
			"94011",
			"94013",
			"94014",
			"94015",
			"94016",
			"94017",
			"94018",
			"94019",
			"94020",
			"94021",
			"94025",
			"94026",
			"94027",
			"94028",
			"94030",
			"94037",
			"94038",
			"94044",
			"94060",
			"94061",
			"94062",
			"94063",
			"94064",
			"94065",
			"94066",
			"94070",
			"94074",
			"94080",
			"94083",
			"94128",
			"94303",
			"94401",
			"94402",
			"94403",
			"94404",
			"94497",
			"94022",
			"94023",
			"94024",
			"94035",
			"94039",
			"94040",
			"94041",
			"94042",
			"94043",
			"94085",
			"94086",
			"94087",
			"94088",
			"94089",
			"94301",
			"94302",
			"94304",
			"94305",
			"94306",
			"94309",
			"95002",
			"95008",
			"95009",
			"95011",
			"95013",
			"95014",
			"95015",
			"95020",
			"95021",
			"95026",
			"95030",
			"95031",
			"95032",
			"95035",
			"95036",
			"95037",
			"95038",
			"95042",
			"95044",
			"95046",
			"95050",
			"95051",
			"95052",
			"95053",
			"95054",
			"95055",
			"95056",
			"95070",
			"95071",
			"95101",
			"95103",
			"95106",
			"95108",
			"95109",
			"95110",
			"95111",
			"95112",
			"95113",
			"95115",
			"95116",
			"95117",
			"95118",
			"95119",
			"95120",
			"95121",
			"95122",
			"95123",
			"95124",
			"95125",
			"95126",
			"95127",
			"95128",
			"95129",
			"95130",
			"95131",
			"95132",
			"95133",
			"95134",
			"95135",
			"95136",
			"95138",
			"95139",
			"95140",
			"95141",
			"95148",
			"95150",
			"95151",
			"95152",
			"95153",
			"95154",
			"95155",
			"95156",
			"95157",
			"95158",
			"95159",
			"95160",
			"95161",
			"95164",
			"95170",
			"95172",
			"95173",
			"95190",
			"95191",
			"95192",
			"95193",
			"95194",
			"95196",
			"95001",
			"95003",
			"95005",
			"95006",
			"95007",
			"95010",
			"95017",
			"95018",
			"95019",
			"95033",
			"95041",
			"95060",
			"95061",
			"95062",
			"95063",
			"95064",
			"95065",
			"95066",
			"95067",
			"95073",
			"95076",
			"95077",
			"96001",
			"96002",
			"96003",
			"96007",
			"96008",
			"96011",
			"96013",
			"96016",
			"96017",
			"96019",
			"96028",
			"96033",
			"96040",
			"96047",
			"96051",
			"96056",
			"96062",
			"96065",
			"96069",
			"96070",
			"96071",
			"96073",
			"96079",
			"96084",
			"96087",
			"96088",
			"96089",
			"96095",
			"96096",
			"96099",
			"95910",
			"95936",
			"95944",
			"96111",
			"96118",
			"96124",
			"96125",
			"96126",
			"95568",
			"96014",
			"96023",
			"96025",
			"96027",
			"96031",
			"96032",
			"96034",
			"96037",
			"96038",
			"96039",
			"96044",
			"96049",
			"96050",
			"96057",
			"96058",
			"96064",
			"96067",
			"96085",
			"96086",
			"96094",
			"96097",
			"96134",
			"94510",
			"94512",
			"94533",
			"94534",
			"94535",
			"94571",
			"94585",
			"94589",
			"94590",
			"94591",
			"94592",
			"95620",
			"95625",
			"95687",
			"95688",
			"95696",
			"94922",
			"94923",
			"94926",
			"94927",
			"94928",
			"94931",
			"94951",
			"94952",
			"94953",
			"94954",
			"94955",
			"94975",
			"94999",
			"95401",
			"95402",
			"95403",
			"95404",
			"95405",
			"95406",
			"95407",
			"95409",
			"95412",
			"95416",
			"95419",
			"95421",
			"95425",
			"95430",
			"95431",
			"95433",
			"95436",
			"95439",
			"95441",
			"95442",
			"95444",
			"95446",
			"95448",
			"95450",
			"95452",
			"95462",
			"95465",
			"95471",
			"95472",
			"95473",
			"95476",
			"95480",
			"95486",
			"95487",
			"95492",
			"95497",
			"95307",
			"95313",
			"95316",
			"95319",
			"95323",
			"95326",
			"95328",
			"95350",
			"95351",
			"95352",
			"95353",
			"95354",
			"95355",
			"95356",
			"95357",
			"95358",
			"95360",
			"95361",
			"95363",
			"95367",
			"95368",
			"95380",
			"95381",
			"95382",
			"95385",
			"95386",
			"95387",
			"95397",
			"95659",
			"95668",
			"95674",
			"95676",
			"95953",
			"95957",
			"95982",
			"95991",
			"95992",
			"95993",
			"96021",
			"96022",
			"96029",
			"96035",
			"96055",
			"96059",
			"96061",
			"96063",
			"96074",
			"96075",
			"96078",
			"96080",
			"96090",
			"96092",
			"95527",
			"95552",
			"95563",
			"95595",
			"96010",
			"96024",
			"96041",
			"96046",
			"96048",
			"96052",
			"96076",
			"96091",
			"96093",
			"93201",
			"93207",
			"93208",
			"93218",
			"93219",
			"93221",
			"93223",
			"93227",
			"93235",
			"93237",
			"93244",
			"93247",
			"93256",
			"93257",
			"93258",
			"93260",
			"93261",
			"93262",
			"93265",
			"93267",
			"93270",
			"93271",
			"93272",
			"93274",
			"93275",
			"93277",
			"93278",
			"93279",
			"93282",
			"93286",
			"93290",
			"93291",
			"93292",
			"93603",
			"93615",
			"93618",
			"93633",
			"93647",
			"93666",
			"93670",
			"93673",
			"95305",
			"95309",
			"95310",
			"95314",
			"95321",
			"95327",
			"95329",
			"95335",
			"95346",
			"95347",
			"95364",
			"95370",
			"95372",
			"95373",
			"95375",
			"95379",
			"95383",
			"95605",
			"95606",
			"95607",
			"95612",
			"95616",
			"95617",
			"95618",
			"95627",
			"95637",
			"95645",
			"95653",
			"95679",
			"95691",
			"95694",
			"95695",
			"95697",
			"95698",
			"95776",
			"95798",
			"95799",
			"95937",
			"95692",
			"95901",
			"95903",
			"95918",
			"95919",
			"95922",
			"95925",
			"95935",
			"95961",
			"95962",
			"95972",
			"95981",
			"95214"
		],
		socal: [
			"92222",
			"92227",
			"92231",
			"92232",
			"92233",
			"92243",
			"92244",
			"92249",
			"92250",
			"92251",
			"92257",
			"92259",
			"92266",
			"92273",
			"92275",
			"92281",
			"92283",
			"93592",
			"93222",
			"93225",
			"93243",
			"93252",
			"93524",
			"93560",
			"93596",
			"90001",
			"90002",
			"90003",
			"90004",
			"90005",
			"90006",
			"90007",
			"90008",
			"90009",
			"90010",
			"90011",
			"90012",
			"90013",
			"90014",
			"90015",
			"90016",
			"90017",
			"90018",
			"90019",
			"90020",
			"90021",
			"90022",
			"90023",
			"90024",
			"90025",
			"90026",
			"90027",
			"90028",
			"90029",
			"90030",
			"90031",
			"90032",
			"90033",
			"90034",
			"90035",
			"90036",
			"90037",
			"90038",
			"90039",
			"90040",
			"90041",
			"90042",
			"90043",
			"90044",
			"90045",
			"90046",
			"90047",
			"90048",
			"90049",
			"90050",
			"90051",
			"90052",
			"90053",
			"90054",
			"90055",
			"90056",
			"90057",
			"90058",
			"90059",
			"90060",
			"90061",
			"90062",
			"90063",
			"90064",
			"90065",
			"90066",
			"90067",
			"90068",
			"90069",
			"90070",
			"90071",
			"90072",
			"90073",
			"90074",
			"90075",
			"90076",
			"90077",
			"90078",
			"90079",
			"90080",
			"90081",
			"90082",
			"90083",
			"90084",
			"90086",
			"90087",
			"90088",
			"90089",
			"90090",
			"90091",
			"90093",
			"90094",
			"90095",
			"90096",
			"90099",
			"90101",
			"90102",
			"90103",
			"90134",
			"90189",
			"90201",
			"90202",
			"90209",
			"90210",
			"90211",
			"90212",
			"90213",
			"90220",
			"90221",
			"90222",
			"90223",
			"90224",
			"90230",
			"90231",
			"90232",
			"90233",
			"90239",
			"90240",
			"90241",
			"90242",
			"90245",
			"90247",
			"90248",
			"90249",
			"90250",
			"90251",
			"90254",
			"90255",
			"90260",
			"90261",
			"90262",
			"90263",
			"90264",
			"90265",
			"90266",
			"90267",
			"90270",
			"90272",
			"90274",
			"90275",
			"90277",
			"90278",
			"90280",
			"90290",
			"90291",
			"90292",
			"90293",
			"90294",
			"90295",
			"90296",
			"90301",
			"90302",
			"90303",
			"90304",
			"90305",
			"90306",
			"90307",
			"90308",
			"90309",
			"90310",
			"90311",
			"90312",
			"90313",
			"90397",
			"90398",
			"90401",
			"90402",
			"90403",
			"90404",
			"90405",
			"90406",
			"90407",
			"90408",
			"90409",
			"90410",
			"90411",
			"90501",
			"90502",
			"90503",
			"90504",
			"90505",
			"90506",
			"90507",
			"90508",
			"90509",
			"90510",
			"90601",
			"90602",
			"90603",
			"90604",
			"90605",
			"90606",
			"90607",
			"90608",
			"90609",
			"90610",
			"90612",
			"90637",
			"90638",
			"90639",
			"90640",
			"90650",
			"90651",
			"90652",
			"90659",
			"90660",
			"90661",
			"90662",
			"90670",
			"90671",
			"90701",
			"90702",
			"90703",
			"90704",
			"90706",
			"90707",
			"90710",
			"90711",
			"90712",
			"90713",
			"90714",
			"90715",
			"90716",
			"90717",
			"90723",
			"90731",
			"90732",
			"90733",
			"90734",
			"90744",
			"90745",
			"90746",
			"90747",
			"90748",
			"90749",
			"90755",
			"90801",
			"90802",
			"90803",
			"90804",
			"90805",
			"90806",
			"90807",
			"90808",
			"90809",
			"90810",
			"90813",
			"90814",
			"90815",
			"90822",
			"90831",
			"90832",
			"90833",
			"90834",
			"90835",
			"90840",
			"90842",
			"90844",
			"90845",
			"90846",
			"90847",
			"90848",
			"90853",
			"90888",
			"90895",
			"90899",
			"91001",
			"91003",
			"91006",
			"91007",
			"91008",
			"91009",
			"91010",
			"91011",
			"91012",
			"91016",
			"91017",
			"91020",
			"91021",
			"91023",
			"91024",
			"91025",
			"91030",
			"91031",
			"91040",
			"91041",
			"91042",
			"91043",
			"91046",
			"91066",
			"91077",
			"91101",
			"91102",
			"91103",
			"91104",
			"91105",
			"91106",
			"91107",
			"91108",
			"91109",
			"91110",
			"91114",
			"91115",
			"91116",
			"91117",
			"91118",
			"91121",
			"91123",
			"91124",
			"91125",
			"91126",
			"91129",
			"91131",
			"91182",
			"91184",
			"91185",
			"91188",
			"91189",
			"91191",
			"91199",
			"91201",
			"91202",
			"91203",
			"91204",
			"91205",
			"91206",
			"91207",
			"91208",
			"91209",
			"91210",
			"91214",
			"91221",
			"91222",
			"91224",
			"91225",
			"91226",
			"91301",
			"91302",
			"91303",
			"91304",
			"91305",
			"91306",
			"91307",
			"91308",
			"91309",
			"91310",
			"91311",
			"91313",
			"91316",
			"91321",
			"91322",
			"91324",
			"91325",
			"91326",
			"91327",
			"91328",
			"91329",
			"91330",
			"91331",
			"91333",
			"91334",
			"91335",
			"91337",
			"91340",
			"91341",
			"91342",
			"91343",
			"91344",
			"91345",
			"91346",
			"91350",
			"91351",
			"91352",
			"91353",
			"91354",
			"91355",
			"91356",
			"91357",
			"91359",
			"91363",
			"91364",
			"91365",
			"91367",
			"91371",
			"91372",
			"91376",
			"91380",
			"91381",
			"91382",
			"91383",
			"91384",
			"91385",
			"91386",
			"91387",
			"91388",
			"91390",
			"91392",
			"91393",
			"91394",
			"91395",
			"91396",
			"91399",
			"91401",
			"91402",
			"91403",
			"91404",
			"91405",
			"91406",
			"91407",
			"91408",
			"91409",
			"91410",
			"91411",
			"91412",
			"91413",
			"91416",
			"91423",
			"91426",
			"91436",
			"91470",
			"91482",
			"91495",
			"91496",
			"91497",
			"91499",
			"91501",
			"91502",
			"91503",
			"91504",
			"91505",
			"91506",
			"91507",
			"91508",
			"91510",
			"91521",
			"91522",
			"91523",
			"91526",
			"91601",
			"91602",
			"91603",
			"91604",
			"91605",
			"91606",
			"91607",
			"91608",
			"91609",
			"91610",
			"91611",
			"91612",
			"91614",
			"91615",
			"91616",
			"91617",
			"91618",
			"91702",
			"91706",
			"91711",
			"91714",
			"91715",
			"91716",
			"91722",
			"91723",
			"91724",
			"91731",
			"91732",
			"91733",
			"91734",
			"91735",
			"91740",
			"91741",
			"91744",
			"91745",
			"91746",
			"91747",
			"91748",
			"91749",
			"91750",
			"91754",
			"91755",
			"91756",
			"91765",
			"91766",
			"91767",
			"91768",
			"91769",
			"91770",
			"91771",
			"91772",
			"91773",
			"91775",
			"91776",
			"91778",
			"91780",
			"91788",
			"91789",
			"91790",
			"91791",
			"91792",
			"91793",
			"91795",
			"91797",
			"91799",
			"91801",
			"91802",
			"91803",
			"91804",
			"91841",
			"91896",
			"91899",
			"93510",
			"93532",
			"93534",
			"93535",
			"93536",
			"93539",
			"93543",
			"93544",
			"93550",
			"93551",
			"93552",
			"93553",
			"93563",
			"93591",
			"93599",
			"90620",
			"90621",
			"90622",
			"90623",
			"90624",
			"90630",
			"90631",
			"90632",
			"90633",
			"90680",
			"90720",
			"90721",
			"90740",
			"90742",
			"90743",
			"92602",
			"92603",
			"92604",
			"92605",
			"92606",
			"92607",
			"92609",
			"92610",
			"92612",
			"92614",
			"92615",
			"92616",
			"92617",
			"92618",
			"92619",
			"92620",
			"92623",
			"92624",
			"92625",
			"92626",
			"92627",
			"92628",
			"92629",
			"92630",
			"92637",
			"92646",
			"92647",
			"92648",
			"92649",
			"92650",
			"92651",
			"92652",
			"92653",
			"92654",
			"92655",
			"92656",
			"92657",
			"92658",
			"92659",
			"92660",
			"92661",
			"92662",
			"92663",
			"92672",
			"92673",
			"92674",
			"92675",
			"92676",
			"92677",
			"92678",
			"92679",
			"92683",
			"92684",
			"92685",
			"92688",
			"92690",
			"92691",
			"92692",
			"92693",
			"92694",
			"92697",
			"92698",
			"92701",
			"92702",
			"92703",
			"92704",
			"92705",
			"92706",
			"92707",
			"92708",
			"92709",
			"92710",
			"92711",
			"92712",
			"92725",
			"92728",
			"92735",
			"92780",
			"92781",
			"92782",
			"92799",
			"92801",
			"92802",
			"92803",
			"92804",
			"92805",
			"92806",
			"92807",
			"92808",
			"92809",
			"92811",
			"92812",
			"92814",
			"92815",
			"92816",
			"92817",
			"92821",
			"92822",
			"92823",
			"92825",
			"92831",
			"92832",
			"92833",
			"92834",
			"92835",
			"92836",
			"92837",
			"92838",
			"92840",
			"92841",
			"92842",
			"92843",
			"92844",
			"92845",
			"92846",
			"92850",
			"92856",
			"92857",
			"92859",
			"92861",
			"92862",
			"92863",
			"92864",
			"92865",
			"92866",
			"92867",
			"92868",
			"92869",
			"92870",
			"92871",
			"92885",
			"92886",
			"92887",
			"92899",
			"91752",
			"92201",
			"92202",
			"92203",
			"92210",
			"92211",
			"92220",
			"92223",
			"92225",
			"92226",
			"92230",
			"92234",
			"92235",
			"92236",
			"92239",
			"92240",
			"92241",
			"92247",
			"92248",
			"92253",
			"92254",
			"92255",
			"92258",
			"92260",
			"92261",
			"92262",
			"92263",
			"92264",
			"92270",
			"92274",
			"92276",
			"92282",
			"92292",
			"92320",
			"92501",
			"92502",
			"92503",
			"92504",
			"92505",
			"92506",
			"92507",
			"92508",
			"92509",
			"92513",
			"92514",
			"92515",
			"92516",
			"92517",
			"92518",
			"92519",
			"92521",
			"92522",
			"92530",
			"92531",
			"92532",
			"92536",
			"92539",
			"92543",
			"92544",
			"92545",
			"92546",
			"92548",
			"92549",
			"92551",
			"92552",
			"92553",
			"92554",
			"92555",
			"92556",
			"92557",
			"92561",
			"92562",
			"92563",
			"92564",
			"92567",
			"92570",
			"92571",
			"92572",
			"92581",
			"92582",
			"92583",
			"92584",
			"92585",
			"92586",
			"92587",
			"92589",
			"92590",
			"92591",
			"92592",
			"92593",
			"92595",
			"92596",
			"92599",
			"92860",
			"92877",
			"92878",
			"92879",
			"92880",
			"92881",
			"92882",
			"92883",
			"91701",
			"91708",
			"91709",
			"91710",
			"91729",
			"91730",
			"91737",
			"91739",
			"91743",
			"91758",
			"91759",
			"91761",
			"91762",
			"91763",
			"91764",
			"91784",
			"91785",
			"91786",
			"91798",
			"92242",
			"92252",
			"92256",
			"92267",
			"92268",
			"92277",
			"92278",
			"92280",
			"92284",
			"92285",
			"92286",
			"92301",
			"92304",
			"92305",
			"92307",
			"92308",
			"92313",
			"92314",
			"92315",
			"92316",
			"92317",
			"92318",
			"92321",
			"92322",
			"92323",
			"92324",
			"92325",
			"92326",
			"92327",
			"92332",
			"92333",
			"92334",
			"92335",
			"92336",
			"92337",
			"92338",
			"92339",
			"92340",
			"92341",
			"92342",
			"92344",
			"92345",
			"92346",
			"92347",
			"92350",
			"92352",
			"92354",
			"92356",
			"92357",
			"92358",
			"92359",
			"92363",
			"92365",
			"92366",
			"92368",
			"92369",
			"92371",
			"92372",
			"92373",
			"92374",
			"92375",
			"92376",
			"92377",
			"92378",
			"92382",
			"92385",
			"92386",
			"92391",
			"92392",
			"92393",
			"92394",
			"92395",
			"92397",
			"92399",
			"92401",
			"92402",
			"92403",
			"92404",
			"92405",
			"92406",
			"92407",
			"92408",
			"92410",
			"92411",
			"92412",
			"92413",
			"92414",
			"92415",
			"92418",
			"92423",
			"92424",
			"92427",
			"91901",
			"91902",
			"91903",
			"91905",
			"91906",
			"91908",
			"91909",
			"91910",
			"91911",
			"91912",
			"91913",
			"91914",
			"91915",
			"91916",
			"91917",
			"91921",
			"91931",
			"91932",
			"91933",
			"91934",
			"91935",
			"91941",
			"91942",
			"91943",
			"91944",
			"91945",
			"91946",
			"91947",
			"91948",
			"91950",
			"91951",
			"91962",
			"91963",
			"91976",
			"91977",
			"91978",
			"91979",
			"91980",
			"91987",
			"91990",
			"92003",
			"92004",
			"92007",
			"92008",
			"92009",
			"92010",
			"92011",
			"92013",
			"92014",
			"92018",
			"92019",
			"92020",
			"92021",
			"92022",
			"92023",
			"92024",
			"92025",
			"92026",
			"92027",
			"92028",
			"92029",
			"92030",
			"92033",
			"92036",
			"92037",
			"92038",
			"92039",
			"92040",
			"92046",
			"92049",
			"92051",
			"92052",
			"92054",
			"92055",
			"92056",
			"92057",
			"92058",
			"92059",
			"92060",
			"92061",
			"92064",
			"92065",
			"92066",
			"92067",
			"92068",
			"92069",
			"92070",
			"92071",
			"92072",
			"92074",
			"92075",
			"92078",
			"92079",
			"92081",
			"92082",
			"92083",
			"92084",
			"92085",
			"92086",
			"92088",
			"92090",
			"92091",
			"92092",
			"92093",
			"92096",
			"92101",
			"92102",
			"92103",
			"92104",
			"92105",
			"92106",
			"92107",
			"92108",
			"92109",
			"92110",
			"92111",
			"92112",
			"92113",
			"92114",
			"92115",
			"92116",
			"92117",
			"92118",
			"92119",
			"92120",
			"92121",
			"92122",
			"92123",
			"92124",
			"92126",
			"92127",
			"92128",
			"92129",
			"92130",
			"92131",
			"92132",
			"92133",
			"92134",
			"92135",
			"92136",
			"92137",
			"92138",
			"92139",
			"92140",
			"92142",
			"92143",
			"92145",
			"92147",
			"92149",
			"92150",
			"92152",
			"92153",
			"92154",
			"92155",
			"92158",
			"92159",
			"92160",
			"92161",
			"92162",
			"92163",
			"92164",
			"92165",
			"92166",
			"92167",
			"92168",
			"92169",
			"92170",
			"92171",
			"92172",
			"92173",
			"92174",
			"92175",
			"92176",
			"92177",
			"92178",
			"92179",
			"92182",
			"92184",
			"92186",
			"92187",
			"92190",
			"92191",
			"92192",
			"92193",
			"92194",
			"92195",
			"92196",
			"92197",
			"92198",
			"92199",
			"93435",
			"93013",
			"93014",
			"93067",
			"93101",
			"93102",
			"93103",
			"93105",
			"93106",
			"93107",
			"93108",
			"93109",
			"93110",
			"93111",
			"93116",
			"93117",
			"93118",
			"93120",
			"93121",
			"93130",
			"93140",
			"93150",
			"93160",
			"93190",
			"93199",
			"93254",
			"93427",
			"93429",
			"93434",
			"93436",
			"93437",
			"93438",
			"93440",
			"93441",
			"93454",
			"93455",
			"93456",
			"93457",
			"93458",
			"93460",
			"93463",
			"93464",
			"91319",
			"91320",
			"91358",
			"91360",
			"91361",
			"91362",
			"91377",
			"93001",
			"93002",
			"93003",
			"93004",
			"93005",
			"93006",
			"93007",
			"93009",
			"93010",
			"93011",
			"93012",
			"93015",
			"93016",
			"93020",
			"93021",
			"93022",
			"93023",
			"93024",
			"93030",
			"93031",
			"93032",
			"93033",
			"93034",
			"93035",
			"93036",
			"93040",
			"93041",
			"93042",
			"93043",
			"93044",
			"93060",
			"93061",
			"93062",
			"93063",
			"93064",
			"93065",
			"93066",
			"93093",
			"93094",
			"93099"
		],
		highInstallationCost: [
			"94501",
			"94502",
			"94536",
			"94537",
			"94538",
			"94539",
			"94540",
			"94541",
			"94542",
			"94543",
			"94544",
			"94545",
			"94546",
			"94550",
			"94551",
			"94552",
			"94555",
			"94557",
			"94560",
			"94566",
			"94568",
			"94577",
			"94578",
			"94579",
			"94580",
			"94586",
			"94587",
			"94588",
			"94601",
			"94602",
			"94603",
			"94604",
			"94605",
			"94606",
			"94607",
			"94608",
			"94690",
			"94610",
			"94611",
			"94612",
			"94613",
			"94614",
			"94615",
			"94617",
			"94618",
			"94619",
			"94620",
			"94621",
			"94622",
			"94623",
			"94624",
			"94625",
			"94649",
			"94659",
			"94660",
			"94661",
			"94662",
			"94666",
			"94701",
			"94702",
			"94703",
			"94704",
			"94705",
			"94706",
			"94707",
			"94708",
			"94790",
			"94710",
			"94712",
			"94720",
			"94505",
			"94506",
			"94507",
			"94590",
			"94511",
			"94513",
			"94514",
			"94516",
			"94517",
			"94518",
			"94519",
			"94520",
			"94521",
			"94522",
			"94523",
			"94524",
			"94525",
			"94526",
			"94527",
			"94528",
			"94529",
			"94530",
			"94531",
			"94547",
			"94548",
			"94549",
			"94553",
			"94556",
			"94561",
			"94563",
			"94564",
			"94565",
			"94569",
			"94570",
			"94572",
			"94575",
			"94582",
			"94583",
			"94595",
			"94596",
			"94597",
			"94598",
			"94801",
			"94802",
			"94803",
			"94804",
			"94805",
			"94806",
			"94807",
			"94808",
			"94820",
			"94850",
			"94901",
			"94903",
			"94904",
			"94912",
			"94913",
			"94914",
			"94915",
			"94920",
			"94924",
			"94925",
			"94929",
			"94930",
			"94933",
			"94937",
			"94938",
			"94939",
			"94940",
			"94941",
			"94942",
			"94945",
			"94946",
			"94947",
			"94948",
			"94949",
			"94950",
			"94956",
			"94957",
			"94960",
			"94963",
			"94964",
			"94965",
			"94966",
			"94970",
			"94971",
			"94972",
			"94973",
			"94974",
			"94976",
			"94977",
			"94978",
			"94979",
			"94998",
			"93426",
			"93450",
			"93901",
			"93902",
			"93905",
			"93906",
			"93907",
			"93908",
			"93912",
			"93915",
			"93920",
			"93921",
			"93922",
			"93923",
			"93924",
			"93925",
			"93926",
			"93927",
			"93928",
			"93930",
			"93932",
			"93933",
			"93940",
			"93942",
			"93943",
			"93944",
			"93950",
			"93953",
			"93954",
			"93955",
			"93960",
			"93962",
			"95004",
			"95012",
			"95039",
			"94503",
			"94508",
			"94515",
			"94558",
			"94559",
			"94562",
			"94567",
			"94573",
			"94574",
			"94576",
			"94581",
			"94599",
			"94101",
			"94102",
			"94103",
			"94104",
			"94105",
			"94106",
			"94107",
			"94108",
			"94190",
			"94110",
			"94111",
			"94112",
			"94114",
			"94115",
			"94116",
			"94117",
			"94118",
			"94119",
			"94120",
			"94121",
			"94122",
			"94123",
			"94124",
			"94125",
			"94126",
			"94127",
			"94129",
			"94130",
			"94131",
			"94132",
			"94133",
			"94134",
			"94135",
			"94136",
			"94137",
			"94138",
			"94139",
			"94140",
			"94141",
			"94142",
			"94143",
			"94144",
			"94145",
			"94146",
			"94147",
			"94150",
			"94151",
			"94152",
			"94153",
			"94154",
			"94155",
			"94156",
			"94158",
			"94159",
			"94160",
			"94161",
			"94162",
			"94163",
			"94164",
			"94171",
			"94172",
			"94175",
			"94177",
			"94188",
			"94199",
			"94002",
			"94005",
			"94010",
			"94011",
			"94013",
			"94014",
			"94015",
			"94016",
			"94017",
			"94018",
			"94019",
			"94020",
			"94021",
			"94025",
			"94026",
			"94027",
			"94028",
			"94030",
			"94037",
			"94038",
			"94044",
			"94060",
			"94061",
			"94062",
			"94063",
			"94064",
			"94065",
			"94066",
			"94070",
			"94074",
			"94080",
			"94083",
			"94128",
			"94303",
			"94401",
			"94402",
			"94403",
			"94404",
			"94497",
			"94022",
			"94023",
			"94024",
			"94035",
			"94039",
			"94040",
			"94041",
			"94042",
			"94043",
			"94085",
			"94086",
			"94087",
			"94088",
			"94089",
			"94301",
			"94302",
			"94304",
			"94305",
			"94306",
			"94390",
			"95002",
			"95008",
			"95090",
			"95011",
			"95013",
			"95014",
			"95015",
			"95020",
			"95021",
			"95026",
			"95030",
			"95031",
			"95032",
			"95035",
			"95036",
			"95037",
			"95038",
			"95042",
			"95044",
			"95046",
			"95050",
			"95051",
			"95052",
			"95053",
			"95054",
			"95055",
			"95056",
			"95070",
			"95071",
			"95101",
			"95103",
			"95106",
			"95108",
			"95190",
			"95110",
			"95111",
			"95112",
			"95113",
			"95115",
			"95116",
			"95117",
			"95118",
			"95119",
			"95120",
			"95121",
			"95122",
			"95123",
			"95124",
			"95125",
			"95126",
			"95127",
			"95128",
			"95129",
			"95130",
			"95131",
			"95132",
			"95133",
			"95134",
			"95135",
			"95136",
			"95138",
			"95139",
			"95140",
			"95141",
			"95148",
			"95150",
			"95151",
			"95152",
			"95153",
			"95154",
			"95155",
			"95156",
			"95157",
			"95158",
			"95159",
			"95160",
			"95161",
			"95164",
			"95170",
			"95172",
			"95173",
			"95190",
			"95191",
			"95192",
			"95193",
			"95194",
			"95196",
			"95001",
			"95003",
			"95005",
			"95006",
			"95007",
			"95010",
			"95017",
			"95018",
			"95019",
			"95033",
			"95041",
			"95060",
			"95061",
			"95062",
			"95063",
			"95064",
			"95065",
			"95066",
			"95067",
			"95073",
			"95076",
			"95077",
			"94922",
			"94923",
			"94926",
			"94927",
			"94928",
			"94931",
			"94951",
			"94952",
			"94953",
			"94954",
			"94955",
			"94975",
			"94999",
			"95401",
			"95402",
			"95403",
			"95404",
			"95405",
			"95406",
			"95407",
			"95490",
			"95412",
			"95416",
			"95419",
			"95421",
			"95425",
			"95430",
			"95431",
			"95433",
			"95436",
			"95439",
			"95441",
			"95442",
			"95444",
			"95446",
			"95448",
			"95450",
			"95452",
			"95462",
			"95465",
			"95471",
			"95472",
			"95473",
			"95476",
			"95480",
			"95486",
			"95487",
			"95492",
			"95497"
		]
	}
};
