import Contact from "./Contact.svelte";
import contact, {
	CONTACT_PAGES,
	CONTACT_VARIANTS,
	EXPAND_STEPS,
	HELLO_STEPS,
	MESSAGE_STEPS,
	SHOWROOM_STEPS
} from "./stores/contact.js";
import { get } from "svelte/store";

function createContact() {
	const target = document.querySelector("#contact");

	const instance = new Contact({
		target
	});

	return function destroyContact() {
		target.classList.add("dismiss");
		instance.$destroy();
		target.classList.remove("dismiss");
	};
}

let destroy;

window.Contact = {
	show(configuration = {}) {
		const { page, variant, step, data } = configuration;

		if (destroy !== undefined) {
			return;
		}

		if (page === CONTACT_PAGES.hello) {
			contact.resetData();
		}

		const params = new URLSearchParams(location.search);

		Object.entries({
			utm_campaign: "utmCampaign",
			utm_source: "utmSource",
			utm_term: "utmTerm",
			gclid: "gclid"
		}).forEach(([a, b]) => {
			if (params.has(a)) {
				contact.updateData(b, params.get(a));
			}
		});

		if (variant !== undefined) {
			contact.variant(
				CONTACT_VARIANTS[
					Object.keys(CONTACT_VARIANTS).find((key) => key === variant)
				],
				data
			);
		}

		if (data !== undefined) {
			Object.entries(data).forEach(([key, value]) => {
				contact.updateData(key, value);
			});
		}

		contact.openPage(page || CONTACT_PAGES.home);

		if (step) {
			contact.openMessageStep(step);
		} else if (page === CONTACT_PAGES.message) {
			contact.openMessageStep(MESSAGE_STEPS.compose);
		} else if (page === CONTACT_PAGES.expand) {
			contact.openExpandStep(EXPAND_STEPS.entry);
		} else if (page === CONTACT_PAGES.hello) {
			const { helloStep, helloStepChangedAt } = get(contact);

			if (
				helloStep !== HELLO_STEPS.success &&
				helloStep !== HELLO_STEPS.survey
			) {
				contact.openHelloStep(HELLO_STEPS.compose);
			}

			const maximumInterval =
				process.env.NODE_ENV === "development"
					? 1 * 60 * 1000
					: 1 * 60 * 60 * 1000;

			const delta = Date.now() - helloStepChangedAt;

			if (helloStepChangedAt === undefined || delta > maximumInterval) {
				contact.openHelloStep(HELLO_STEPS.compose);
			}

			// TODO: remove!
			// contact.openHelloStep(HELLO_STEPS.success);
		} else if (page === CONTACT_PAGES.showroom) {
			contact.openShowroomStep(SHOWROOM_STEPS.compose);
		}

		destroy = createContact();

		gtag("event", "contact_form_opened", { page, variant });
	},

	hide() {
		if (destroy === undefined) {
			return;
		}

		destroy();
		destroy = undefined;
	}
};
